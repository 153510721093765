<template>
  <div class="bg-gray-50 min-h-screen relative">
    <div class="p-4 pt-8 container max-w-screen-lg mx-auto">
      <section class="grid grid-cols-2 gap-x my-6">
        <span class="text-xl p-2 uppercase tracking-wide">
          Calon Peserta Didik
        </span>
        <div class="flex flex-col items-end">
          <button class="button --primary" @click="triggerShowForm()">
            <fa-icon icon="plus"></fa-icon>
            <span class="ml-2">Calon</span>
          </button>
        </div>
      </section>

      <!-- search form -->
      <search-form></search-form>

      <!-- candidate list -->
      <div class="p-2 text-center" v-if="candidates.length === 0">
        Belum ada calon peserta didik
      </div>
      <div class="sm:grid sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <candidate-list-item
          v-for="(item, key) in candidates"
          :key="key"
          :item="item"
          :itemKey="key"
          @downloading="triggerDownload"
        ></candidate-list-item>
      </div>

      <!-- pagination -->
      <pagination-links
        @clicked="handlePaginate"
        :links="links"
      ></pagination-links>

      <!-- editing form -->
      <period-form></period-form>

      <!-- downloadable view -->
      <section class="hidden">
        <div id="downloadable" class="left-0" v-if="downloadable">
          <p class="text-2xl font-bold mb-6">DATA INTI</p>
          <p
            v-for="(item, key) in downloadable.core"
            :key="`core-${key}`"
            class="mb-4"
          >
            <span class="block font-bold uppercase tracking-wider">{{
              key
            }}</span>
            <span>{{ item || "..." }}</span>
          </p>

          <p class="text-2xl font-bold mt-10 mb-6 page-breaker">ALAMAT</p>
          <p
            v-for="(item, key) in downloadable.address"
            :key="`core-${key}`"
            class="mb-4"
          >
            <span class="block font-bold uppercase tracking-wider">{{
              key
            }}</span>
            <span>{{ item || "..." }}</span>
          </p>

          <!-- Parents -->
          <div
            v-for="(parent, parentKey) in downloadable.parents"
            :key="`parents-${parentKey}`"
          >
            <p class="uppercase text-2xl font-bold mt-10 mb-6 page-breaker">
              {{ parent.type }}
            </p>
            <p
              v-for="(item, key) in parent.data"
              :key="`${parent.type}-${key}`"
              class="mb-4"
            >
              <span class="block font-bold uppercase tracking-wider">{{
                key
              }}</span>
              <span>{{ item || "..." }}</span>
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { mapActions, mapGetters, mapMutations } from "vuex";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export default {
  data() {
    return {
      downloadable: {},
    };
  },
  methods: {
    async triggerDownload(param) {
      this.$toasted.info("Mengambil Data");
      let response = await Axios.get(
        `api/student/downloadable/${param.candidate}`
      );
      this.downloadable = {
        core: response.data.core,
        address: response.data.address,
        parents: response.data.parents,
      };

      let coreText = [{ text: "\n" }];
      let addressText = [{ text: "\n" }];
      let parentsText = {
        father: [{ text: "\n" }],
        mother: [{ text: "\n" }],
        guardian: [{ text: "\n" }],
      };

      for (const [key, value] of Object.entries(this.downloadable.core)) {
        coreText.push({ text: key + "\n", fontSize: 9 });
        coreText.push({ text: (value ? value : "") + "\n\n", bold: true });
      }

      for (const [key, value] of Object.entries(this.downloadable.address)) {
        addressText.push({ text: key + "\n", fontSize: 9 });
        addressText.push({ text: (value ? value : "") + "\n\n", bold: true });
      }

      this.downloadable.parents.forEach((parent) => {
        for (const [key, value] of Object.entries(parent.data)) {
          if (parent.type === "ayah") {
            parentsText.father.push({ text: key + "\n", fontSize: 9 });
            parentsText.father.push({
              text: (value ? value : "") + "\n\n",
              bold: true,
            });
          } else if (parent.type === "ibu") {
            parentsText.mother.push({ text: key + "\n", fontSize: 9 });
            parentsText.mother.push({
              text: (value ? value : "") + "\n\n",
              bold: true,
            });
          } else {
            parentsText.guardian.push({ text: key + "\n", fontSize: 9 });
            parentsText.guardian.push({
              text: (value ? value : "") + "\n\n",
              bold: true,
            });
          }
        }
      });

      let docDefinition = {
        pageSize: "A4",
        pageMargins: [40, 60, 40, 60],
        content: [
          {
            columns: [
              { text: "DATA DIRI", style: "title" },
              { text: "ALAMAT", style: "title" },
            ],
          },
          {
            columns: [{ text: coreText }, { text: addressText }],
          },
          {
            columns: [
              { text: "AYAH", style: "title" },
              { text: "IBU", style: "title" },
              { text: "WALI", style: "title" },
            ],
            pageBreak: "before",
          },
          {
            columns: [
              { text: parentsText.father },
              { text: parentsText.mother },
              { text: parentsText.guardian },
            ],
          },
        ],
        styles: {
          title: {
            fontSize: 22,
            bold: true,
          },
        },
        defaultStyle: {
          columnGap: 20,
        },
      };

      pdfMake.createPdf(docDefinition).print();
    },
    triggerShowForm() {
      this.SHOW_INIT_FORM(null);
    },
    async handlePaginate(url) {
      this.fetchCandidatesUsingPagination(url);
    },
    /** Mutations from candidate store */
    ...mapMutations("candidate", ["RESET", "SHOW_INIT_FORM"]),
    /** Actions from candidate store */
    ...mapActions("candidate", [
      "fetchCandidates",
      "fetchCandidatesUsingPagination",
    ]),
  },
  computed: {
    /** Getters from candidate store */
    ...mapGetters("candidate", ["candidates", "links"]),
  },
  async mounted() {
    await this.fetchCandidates();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  },
  beforeDestroy() {
    this.RESET();
  },
  name: "CandidateList",
  components: {
    PeriodForm: () => import("@/components/Registration/Period/PeriodForm.vue"),
    CandidateListItem: () =>
      import("@/components/Registration/Candidate/CandidateListItem.vue"),
    PaginationLinks: () => import("@/components/PaginationLinks.vue"),

    SearchForm: () =>
      import("@/components/Registration/Candidate/SearchForm.vue"),
  },
};
</script>